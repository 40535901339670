// @ts-check
import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Typography from "../../components/typography"

import CardVideoHero from "../../components/cards/cardVideoHero"
import CardVideoSTPD from "../../components/cards/cardVideoSTPD"
import CardVideoFeatures from "../../components/cards/cardVideoFeatures"
import CardVideoAdmin from "../../components/cards/cardVideoAdmin"
import CardVideoMinicards from "../../components/cards/cardVideoMinicards"
import CardVideoDouble from "../../components/cards/cardVideoDouble"
import CardPartners from "../../components/cards/cardPartners"
import DemoEmailWidget from "../../components/demoEmailWidget"

const VideoPage = () => {
  return (
    <Layout bg="dark" newsletter={null}>
      <SEO title="Live Video" />

      <CardVideoHero
        widget={
          <DemoEmailWidget
            id="hero"
            style={{ marginTop: 20 }}
            preInputNode={
              <Typography
                variant="h6"
                style={{ alignSelf: "stretch", marginTop: 40 }}
              >
                Schedule a demo today
              </Typography>
            }
          />
        }
      />
      <CardVideoSTPD />
      <CardVideoFeatures />
      <CardVideoAdmin />
      <CardVideoMinicards />
      <CardVideoDouble
        widget={<DemoEmailWidget id="double-card" style={{ marginTop: 20 }} />}
      />
      <CardPartners variant="dark" />
    </Layout>
  )
}

export default VideoPage
