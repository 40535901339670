// @ts-check
import React from "react"
import styled from "@emotion/styled"

import Card from "../card"
import colors from "../../styles/colors"
import { typographyComponents } from "../typography"

const Icons = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  color: ${colors.primary};

  & svg:not(:last-of-type) {
    margin-right: 80px;

    @media (max-width: 768px) {
      margin-right: 30px;
    }
  }
`
const Title = styled(typographyComponents.h1.withComponent("h2"))`
  align-self: stretch;
  margin-top: 50px;
  text-align: center;
  color: ${colors.primary};
`
const Copy = styled(typographyComponents.caption.withComponent("div"))`
  align-self: center;
  margin-top: 20px;
  max-width: 630px;
  text-align: center;

  p {
    margin-bottom: 20px;
  }
`

const IconShow = p => (
  <svg fill="currentColor" viewBox="0 0 218 150" {...p}>
    <path d="M108.9.5A117 117 0 000 74.8a117 117 0 00217.7 0A117 117 0 00109 .5zm0 123.7a49.5 49.5 0 110-99 49.5 49.5 0 010 99zM79.2 74.7a29.6 29.6 0 1159.4 0 29.6 29.6 0 11-59.4 0z" />
  </svg>
)

const IconTalk = p => (
  <svg fill="currentColor" viewBox="0 0 226 155" {...p}>
    <path d="M164.2 10.4C145.5-5.4 126.7 1 113 12.7 99.2 1 80.5-5.4 61.8 10.4 43.1 26.4 0 78.4 0 78.4S46.8 155 113 155c66.3 0 113-76.4 113-76.4s-43.2-52.2-61.8-68zm-51.2 82c-18.8 0-40.8-16.3-40.8-16.3s22-16.4 40.8-16.4c18.9 0 40.8 16.4 40.8 16.4s-22 16.3-40.8 16.3z" />
  </svg>
)

const IconPoint = p => (
  <svg fill="currentColor" viewBox="0 0 187 188" {...p}>
    <path d="M182.3 84.4L102.6 4.1v-.2a13.2 13.2 0 00-18.8 0l-80 80.4a13.4 13.4 0 000 18.9 13.2 13.2 0 0018.8 0L80 45.6v128.6c0 7.3 6 13.3 13.2 13.3 7.4 0 13.3-6 13.3-13.3V45.7l57.3 57.6a13.2 13.2 0 0018.7 0 13.3 13.3 0 000-18.9z" />
  </svg>
)

const IconDraw = p => (
  <svg fill="currentColor" viewBox="0 0 167 167" {...p}>
    <path d="M66.7 148.7L12 166.5a11 11 0 01-12-12l17.8-54.8a11 11 0 013.1-6.7l89.5-89.5a11 11 0 0115.5 0l37 37a11 11 0 010 15.6l-89.4 89.5c-1.9 1.8-4.2 2.9-6.8 3.1z" />
  </svg>
)

const CardVideoSTPD = () => {
  return (
    <Card variant="dark">
      <Icons>
        <IconShow style={{ flex: 1.15 }} />
        <IconTalk style={{ flex: 1.2 }} />
        <IconPoint style={{ flex: 1 }} />
        <IconDraw style={{ flex: 0.85 }} />
      </Icons>
      <Title>Show. Talk. Point. Draw.</Title>
      <Copy>
        <p>
          Video is the new standard for communication, for everyone. Your
          customers are not only comfortable with using video, they expect to
          find all the tools they use daily in consumer apps, like drawing on
          the screen or sharing&nbsp;images.
        </p>
        <p>
          We’ve made these powerful tools extremely simple and intuitive, so
          that customers can explain their problems without friction, and you
          can reach a solution faster than ever&nbsp;before.
        </p>
      </Copy>
    </Card>
  )
}

export default CardVideoSTPD
