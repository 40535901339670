// @ts-check
import React from "react"

import Card, { DoubleCard } from "../card"
import Typography from "../typography"
import { List, ListItem } from "../list"
import colors from "../../styles/colors"

/**
 * @param {Object} props
 * @param {React.ReactNode} [props.widget=null]
 */
const CardVideoDouble = ({ widget = null }) => (
  <DoubleCard>
    <Card noMinHeight variant="primary">
      <Typography variant="h2" as="h3">
        Let Mavenoid handle it
      </Typography>

      <List style={{ marginTop: 50 }}>
        <ListItem>Triage of incoming requests</ListItem>
        <ListItem>Instructions for setup and use</ListItem>
        <ListItem>Self-service troubleshooting</ListItem>
        <ListItem>Live video and chat support</ListItem>
        <ListItem>Decision support for agents</ListItem>
      </List>
    </Card>
    <Card noMinHeight variant="lightpurple">
      <Typography variant="h2" as="h3" style={{ color: colors.primary }}>
        Get started with video support
      </Typography>
      <Typography variant="bodyLarge" as="div" style={{ marginTop: 50 }}>
        Contact us to learn more and get&nbsp;a&nbsp;demo
      </Typography>
      {widget}
    </Card>
  </DoubleCard>
)

export default CardVideoDouble
