// @ts-check
import React from "react"
import styled from "@emotion/styled"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

import Card from "../card"
import colors from "../../styles/colors"
import { typographyComponents } from "../typography"

const Columns = styled.div`
  align-self: stretch;
  display: flex;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`
const ColumnPicture = styled.div`
  flex: 1.5;

  @media screen and (max-width: 768px) {
    margin-bottom: 50px;
  }
`
const ColumnText = styled.div`
  flex: 1;
  margin-left: 35px;

  @media screen and (max-width: 768px) {
    margin-left: 0;
    padding: 0 20px;
  }
`
const ColumnTextH1 = styled(typographyComponents.h3)`
  color: ${colors.primary};
`
const ColumnTextLabel = styled(
  typographyComponents.caption.withComponent("h3")
)`
  margin-top: 1.5em;
  color: ${colors.primary};
`
const ColumnTextCopy = styled(
  typographyComponents.caption.withComponent("div")
)``

const CardVideoAdmin = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "product/video-admin.png" }) {
        childImageSharp {
          fluid(
            maxWidth: 650
            quality: 80
            traceSVG: {
              color: "#222222"
              background: "#FFF"
              blackOnWhite: true
            }
          ) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)
  return (
    <Card variant="lightpurple" noMinHeight>
      <Columns>
        <ColumnPicture>
          <Img
            fluid={data.file.childImageSharp.fluid}
            alt="Illustration of Mavenoid's Admin Console"
          />
        </ColumnPicture>
        <ColumnText>
          <ColumnTextH1>
            The dashboard gives your agents superpowers
          </ColumnTextH1>
          <ColumnTextLabel>Advanced decision support</ColumnTextLabel>
          <ColumnTextCopy>
            Based on answers from the current and previous customers, Mavenoid
            surfaces context and suggests the most probable solutions in
            real-time to your agents.
          </ColumnTextCopy>

          <ColumnTextLabel>
            The agent makes the AI smarter,
            <br />
            which makes the agent smarter
          </ColumnTextLabel>
          <ColumnTextCopy>
            The platform keeps learning from every interaction. When tickets are
            solved, new information is immediately made available to the other
            members of the team.
          </ColumnTextCopy>
        </ColumnText>
      </Columns>
    </Card>
  )
}

export default CardVideoAdmin
