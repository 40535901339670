// @ts-check
import styled from "@emotion/styled"
import axios from "axios"
import React from "react"

import { isPersonalEmail } from "../../shared/utils"
import { FUNCTIONS_ENDPOINT } from "../config"
import { trackEvent } from "../utils/trackEvent"
import { trackError } from "../utils/segment"
import { SendingButton } from "./button"
import Input from "./input"
import Typography from "./typography"

const WidgetForm = styled.form`
  display: flex;
  align-items: stretch;
  align-self: stretch;

  ${Input} {
    flex: 1;
    margin: 0;
  }

  ${SendingButton} {
    margin: 0;
  }

  @media (min-width: 769px) {
    ${Input} {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    ${SendingButton} {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;

    ${Input} {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    ${SendingButton} {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
`

/** @typedef {object} DemoEmailWidgetProps
 * @prop {string} id
 * @prop {React.ReactNode} [preInputNode] Optional message shown before the form and removed on success
 * @prop {() => void} [onSuccess]
 */

/** @param {DemoEmailWidgetProps & React.FormHTMLAttributes<HTMLFormElement>} props */
const DemoEmailWidget = ({
  id,
  preInputNode = null,
  onSuccess = () => {},
  ...p
}) => {
  const [email, setEmail] = React.useState("")
  const [isSending, setIsSending] = React.useState(false)
  const [status, setStatus] = React.useState(null)

  const onSubmit = async () => {
    setIsSending(true)
    setStatus(null)
    try {
      const response = await axios.post(`${FUNCTIONS_ENDPOINT}/feature-video`, {
        email,
      })
      if (response.data.status === "success") {
        setStatus(true)
        trackEvent({
          category: "sign_up_redirect",
          label: "demo widget",
        })
        onSuccess()
      } else {
        setStatus(false)
        throw new Error(
          `Request failed: ${JSON.stringify(response.data, null, 2)}`
        )
      }
    } catch (e) {
      setStatus(false)
      trackError(e)
    } finally {
      setIsSending(false)
    }
  }

  const isPersonal = isPersonalEmail(email)

  return status ? (
    <Typography
      variant="h4"
      style={{ alignSelf: "stretch", marginTop: 20, marginBottom: 20 }}
    >
      Great! We will get back to you as soon as we can.
    </Typography>
  ) : (
    <>
      {preInputNode}
      <WidgetForm
        onSubmit={e => {
          e.preventDefault()
          if (status === true || isPersonal) return
          onSubmit()
        }}
        {...p}
      >
        <Input
          id={`email-${id}`}
          value={email}
          type="email"
          onChange={e => setEmail(e.target.value)}
          placeholder="Your work email"
          required
          focusBorderVariant="primary"
        />
        <SendingButton isSending={isSending} disabled={isSending || isPersonal}>
          Get started
        </SendingButton>
      </WidgetForm>
      {isPersonal && (
        <Typography variant="small" style={{ marginTop: 10 }}>
          <span aria-hidden>⚠️</span> It looks like you have entered your
          personal e-mail address. Right now our product is intended for
          professional use only. Please use your professional email address
          instead (no Gmail, Hotmail etc).
        </Typography>
      )}
      {status === false && (
        <Typography variant="small" style={{ marginTop: 20 }}>
          It looks like we are having some technical issues. Try again, or
          contact us at support@mavenoid.com
        </Typography>
      )}
    </>
  )
}

export default DemoEmailWidget
