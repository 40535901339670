// @ts-check
import React from "react"
import styled from "@emotion/styled"
import Img from "gatsby-image/withIEPolyfill"

import Card from "../../components/card"
import colors from "../../styles/colors"
import Typography, { typographyComponents } from "../../components/typography"
import { useStaticQuery, graphql } from "gatsby"

const HeroCard = styled(Card)`
  padding: 0 !important;
`
const HeroCardColumns = styled.div`
  align-self: stretch;
  display: flex;
  & > * {
    flex: 1;
    flex-shrink: 0;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`
const HeroCardTitle = styled(typographyComponents.h2)`
  strong {
    display: block;
    color: ${colors.primary};
  }
`
const HeroCardCopy = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > * {
    margin: 0 70px 0 100px;
  }

  @media (max-width: 768px) {
    margin: 30px 0;
    & > * {
      margin: 0 20px;
    }
  }
`

/**
 * @param {Object} props
 * @param {React.ReactNode} [props.widget]
 */
const CardVideoHero = ({ widget = null }) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "product/video-hero.jpg" }) {
        childImageSharp {
          fluid(
            maxWidth: 620
            quality: 80
            traceSVG: {
              color: "#5C477E"
              background: "#311A57"
              blackOnWhite: true
            }
          ) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)
  return (
    <HeroCard variant="lightpurple">
      <HeroCardColumns>
        <HeroCardCopy>
          <HeroCardTitle style={{ marginBottom: "0.75em" }}>
            Get started with video and chat support today
          </HeroCardTitle>
          <Typography variant="caption">
            Live support has never been easier. Nothing to install for you or
            your customers. You can use live support stand-alone, or in
            combination with Mavenoid’s self-service.
          </Typography>
          {widget}
        </HeroCardCopy>
        <Img
          alt="Illustration of a video support live chat in Mavenoid"
          fluid={data.file.childImageSharp.fluid}
        />
      </HeroCardColumns>
    </HeroCard>
  )
}

export default CardVideoHero
