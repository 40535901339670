// @ts-check
import React from "react"
import styled from "@emotion/styled"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

import BaseCard from "../card"
import colors from "../../styles/colors"
import { typographyComponents } from "../typography"
import Button from "../button"
import Link from "../link"

const Card = styled(BaseCard)`
  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
    color: ${colors.dark};
  }
`

const MiniCardsWrapper = styled.div`
  align-self: stretch;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`
const MiniImageCardWrapper = styled.div`
  position: relative;
  max-width: 325px;
  padding: 40px;
  padding-top: 150px;
  margin-top: 50px;
  border-radius: 4px;
  background: ${colors.light};
  color: ${colors.dark};
  text-align: center;

  @media screen and (max-width: 768px) {
    padding: 0 20px;
    margin-top: 20px;
    margin-bottom: 70px;
    background: transparent;
  }
`
const MiniImageWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: -150px;
  padding: 0 50px;

  @media screen and (max-width: 768px) {
    position: initial;
    padding: 0;
    max-width: 150px;
    margin: auto;
  }
`
const MiniImage = styled(Img)`
  border-radius: 4px;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
  img {
    border-radius: 4px;
  }
`
const MiniImageTitle = styled(typographyComponents.label.withComponent("h3"))`
  margin: 33px 0 12px;
`
const MiniImageCopy = styled(typographyComponents.caption.withComponent("p"))`
  margin: 0;
`
const MoreText = styled.div`
  display: flex;
  margin-top: 50px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin-top: 0;
    margin-bottom: 50px;
  }
`
const MoreTextTitle = styled(typographyComponents.h3)`
  flex: 1;
  color: ${colors.primary};
  padding: 0 50px;
  @media screen and (max-width: 768px) {
    padding: 0 30px;
  }
`
const MoreTextCopy = styled(typographyComponents.caption)`
  flex: 1;
  padding: 0 55px;
  margin-top: 10px;

  @media screen and (max-width: 768px) {
    padding: 0 30px;
  }
`
const MoreTextWithLink = styled.div`
  display: flex;
  flex-direction: column;

  a {
    margin-top: 45px;
  }

  @media (min-width: 769px) {
    align-items: flex-start;
  }
`

const MiniImageCard = ({ alt, fluid, title, copy }) => (
  <MiniImageCardWrapper>
    <MiniImageWrapper>
      <MiniImage fluid={fluid} alt={alt} />
    </MiniImageWrapper>
    <MiniImageTitle>{title}</MiniImageTitle>
    <MiniImageCopy>{copy}</MiniImageCopy>
  </MiniImageCardWrapper>
)

/**
 * @param {object} p
 * @param {boolean} [p.onHomePage]
 **/
const CardVideoFeatures = p => {
  const { stepbystep, livechat, livevideo } = useStaticQuery(graphql`
    fragment videoFeatureImage on File {
      childImageSharp {
        fluid(
          maxWidth: 222
          quality: 80
          traceSVG: { color: "#777", background: "#311A57", blackOnWhite: true }
        ) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    query {
      stepbystep: file(relativePath: { eq: "product/video-stepbystep.png" }) {
        ...videoFeatureImage
      }
      livechat: file(relativePath: { eq: "product/video-livechat.png" }) {
        ...videoFeatureImage
      }
      livevideo: file(relativePath: { eq: "product/video-livevideo.png" }) {
        ...videoFeatureImage
      }
    }
  `)
  return (
    <Card variant="dark" {...p}>
      <MiniCardsWrapper>
        <MiniImageCard
          fluid={stepbystep.childImageSharp.fluid}
          alt="Screenshot of Mavenoid troubleshooting interface"
          title="Self-service"
          copy="Repetitive requests are solved by customers themselves before they reach your support team. From FAQs to advanced troubleshooting."
        />
        <MiniImageCard
          fluid={livechat.childImageSharp.fluid}
          alt="Screenshot of Mavenoid live chat interface"
          title="Chat"
          copy="Requests that need a human touch are automatically escalated to your support team. Customers never have to repeat themselves."
        />
        <MiniImageCard
          fluid={livevideo.childImageSharp.fluid}
          alt="Screenshot of Mavenoid live video interface"
          title="Video"
          copy="Sometimes you need to take a closer look. Solve highly complex and unique problems without the need for physical site-visits."
        />
      </MiniCardsWrapper>
      <MoreText>
        <MoreTextTitle>Video completes your support platform</MoreTextTitle>
        <MoreTextCopy>
          {p.onHomePage ? (
            <MoreTextWithLink>
              <div>
                Mavenoid takes the customer seamlessly between self-service,
                chat, and video, without losing one bit of information along the
                way.
              </div>
              <Button as={Link} to="/product">
                Explore Features
              </Button>
            </MoreTextWithLink>
          ) : (
            <>
              Mavenoid takes the customer seamlessly between self&#8209;service,
              chat, and video, without losing one bit of information along the
              way. By balancing cost and time, it finds the fastest and most
              suitable path to solution. It’s optimal both for customers and for
              your support team.
            </>
          )}
        </MoreTextCopy>
      </MoreText>
    </Card>
  )
}

export default CardVideoFeatures
